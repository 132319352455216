/* 
* Template Name    : Treelink, the best "link in bio" responsive template.
* Author           : Jorge Perez
* Version          : 2.0.0
* Updated          : June 2023
* File Description : Video BG Mode CSS.
This affects the color of: text highlights, buttons, bg, social media icons, form & toggle switch */

    /*===================================
        Background Color
    =====================================*/
    body {
        background-color: rgb(0, 0, 0);
    }
    
    /*===================================
        Custom Text Color
    =====================================*/
    .custom-text-color-primary {
        color: #fff;
    }

    .custom-text-color-secondary {
        color: #aaa;
    }

    .header-text {
        text-shadow: 0 2px 1px #1e1e1e;
    }

    .subheader-text {
        text-shadow: 0 1px 1px #1e1e1e;
    }

    .footer-text {
        text-shadow: 0 1px 1px #1e1e1e;
    }
    
    /*===================================
        Img Rounded Circle Border Color
    =====================================*/
    .rounded-circle {
        border: 2px solid rgba(255, 255, 255, 0.25);
    }

    /*===================================
        A Color
    =====================================*/
    a {
        color:#aaa;
    }
    a:hover {
        color: #fff;
    }

    /*===================================
        Back To Top Color
    =====================================*/
    .back_top {
        background-color: #fff;
    }

    .back_top i {
        color: rgb(61, 59, 60);
    }

    /*===================================
        Social Icons
    =====================================*/
    .about-social li a {
        color: white;
        background-color: #434343c2;
        box-shadow: 2px 5px 8px rgba(0,0,0,30%);
        border: 1px solid rgba(255, 255, 255, 0.25);
    }

    .about-social li a:hover {
        border: 1px solid rgba(255, 255, 255, 0.25);
        color: white;
        background-color: rgba(255, 255, 255, 0.40);
    }

    /*===================================
        Main Buttons
    =====================================*/
    .btn-custom {
        color: rgb(255, 255, 255);
        background-color: #434343c2;
        box-shadow: 3px 5px 8px rgba(0,0,0,40%);
        border: 1px solid rgba(255, 255, 255, 0.25);
    }

    .btn-custom:hover,
    .btn-custom:active,
    .btn-custom.active,
    .btn-custom:active,
    .btn-custom:hover,
    .open > .dropdown-toggle.btn-custom {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.40);
        border: 1px solid rgba(255, 255, 255, 0.25);
    }

    .form-control {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: #434343c2;
        box-shadow: 3px 5px 8px rgba(0,0,0,40%);
        border: 1px solid rgba(255, 255, 255, 0.25);
    }

    /*===================================
        Form Switch Colors
    =====================================*/
    .form-control {
        color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: #e1e1e1c2;
        box-shadow: 3px 5px 8px rgba(0,0,0,40%);
        border: 1px solid rgba(255, 255, 255, 0.25);
    }

    /*===================================
        Modal Colors
    =====================================*/
    .modal-content {
        background-color: #434343c2;
    }
