
/* 
* Template Name    : Treelike, the best "link in bio template".
* Author           : Jorge Perez
* Version          : 2.0.0
* Updated          : June 2023
* File Description : Main Font (Font-1.css) :Inter */

/* Replace the url below with your own if needed. */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');

/* Make sure to also replace the font family as well, if needed. */
:root {
  font-family: 'Inter', sans-serif;
    word-wrap: break-word;
    overflow-x: hidden;
    letter-spacing: -0.5px;
}

body {
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
    overflow-x: hidden;
    letter-spacing: -0.5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
    overflow-x: hidden;
    letter-spacing: -0.5px;
}